import { reactive, ref } from "vue";
import useMessage from "@/hooks/useMessage";
import { oweList } from "../../api/info/payRecordApi";
const useOweRecord = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  const auditStates = ref([
    { value: 0, label: "待审核" },
    { value: 1, label: "已审核" },
  ]);
  const parkingRecordVo = reactive({
    parkingLotId: "",
    parkingLotName: "",
    slotNum: "",
    plateNumber: "",
    inTime: "",
    outTime: "",
    state: "",
    parkingTime: "",
    phone: "",
    nickName: "",
    payState: "",
    shouldPayAmount: "",
    discountAmount: "",
    couponValue: "",
    auditTime: "",
    auditBy: "",
    paidAmount: "",
    snapPics: "",
    inPic: "",
    outPic: "",
  });

  const freeRecordVo = reactive({
    parkingRecordId:"",
    parkingLotName: "",
    slotNum: "",
    plateNumber: "",
    inTime: "",
    outTime: "",
    shouldPayAmount: "",
    paidAmount:"",
    oweAmount:"",
    reason: "",
    remark: "",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "车场名称",
      align: "center",
      dataIndex: "parkingLotName",
    },
    {
      title: "泊位号",
      align: "center",
      dataIndex: "slotNum",
    },
    {
      title: "车牌号",
      align: "center",
      dataIndex: "plateNumber",
      slots: { customRender: "plateNumber" },
    },
    {
      title: "进场时间",
      align: "center",
      width: "200px",
      dataIndex: "inTime",
    },
    {
      title: "离场时间",
      align: "center",
      width: "200px",
      dataIndex: "outTime",
    },
    {
      title: "停车时长",
      align: "center",
      dataIndex: "parkingTime",
      slots: { customRender: "parkingTime" },
    },
    {
      title: "应付金额（元）",
      align: "center",
      dataIndex: "shouldPayAmount",
      slots: { customRender: "shouldPayAmount" },
    },
    {
        title: "已付金额（元）",
        align: "center",
        dataIndex: "paidAmount",
        slots: { customRender: "paidAmount" },
    },
    {
      title: "待缴金额（元）",
      align: "center",
      dataIndex: "oweAmount",
      slots: { customRender: "oweAmount" },
    },
    {
      title: "审核时间",
      align: "center",
      dataIndex: "auditTime",
      slots: { customRender: "auditTime" },
    },
    {
      title: "审核操作人",
      align: "center",
      dataIndex: "auditedBy",
      slots: { customRender: "auditedBy" },
    },
    {
      title: "操作",
      align: "center",
      slots: { customRender: "operation" },
    },
  ];
  const money = m => {
    if (typeof m !== "undefined") {
      return (m / 100).toFixed(2);
    }
    return "";
  };
  const getParkingTime = t => {
    if (typeof t !== "undefined") {
      var h = ((t - (t % 3600)) / 3600).toFixed(0);
      var s = ((t % 3600) / 60).toFixed(0);
      return h == 0 ? s + "分钟" : h + "小时" + (s == 0 ? "" : s + "分钟");
    }
    return "";
  };
  const loadData = async (page, pageSize) => {
    loading.value = true;
    let p = {
      payOrderId: params.payOrderId,
      parkingLotId: params.parkingLotId,
      plateNumber: params.plateNumber,
      slotNum: params.slotNum,
      auditState: params.auditState, //审核状态  0-待审核 1-已审核
      carInArr: params.carInArr ? params.carInArr.toString() : [],
      carOutArr: params.carOutArr ? params.carOutArr.toString() : [],
    };
    try {
      let res = await oweList(p, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    payOrderId: "",
    parkingLotId: null,
    plateNumber: "",
    slotNum: "",
    carInArr: [],
    carOutArr: [],
    auditState: null, //审核状态 0-待审核 1-已审核
  });
  return {
    loading,
    params,
    dataList,
    total,
    parkingRecordVo,
    freeRecordVo,
    columns,
    loadData,
    money,
    getParkingTime,
    auditStates,
  };
};
export default useOweRecord;
