<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="停车免单"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <div style="max-height: 450px; overflow-y: scroll">
      <a-form>
        <a-descriptions bordered size="small" :column="1">
          <a-descriptions-item label="车场名称">{{
            freeRecordVo.parkingLotName
          }}</a-descriptions-item>
          <a-descriptions-item label="泊位号">{{
            freeRecordVo.slotNum
          }}</a-descriptions-item>
          <a-descriptions-item label="车牌号">{{
            freeRecordVo.plateNumber
          }}</a-descriptions-item>
          <a-descriptions-item label="应付金额">{{
            freeRecordVo.shouldPayAmount
            }}</a-descriptions-item>
          <a-descriptions-item label="已付金额">{{
            freeRecordVo.paidAmount
            }}</a-descriptions-item>
          <a-descriptions-item label="待缴费金额">{{
            freeRecordVo.oweAmount
            }}</a-descriptions-item>
          <a-descriptions-item label="进场时间">{{
            freeRecordVo.inTime
          }}</a-descriptions-item>
          <a-descriptions-item label="离场时间">{{
            freeRecordVo.outTime
          }}</a-descriptions-item>
          <a-form-item label="免单原因">
            <a-textarea
              :row="2"
              v-model:value="freeRecordVo.reason"
              style="width: 100%"
            ></a-textarea>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea
              :row="2"
              v-model:value="freeRecordVo.remark"
              style="width: 100%"
            ></a-textarea>
          </a-form-item>
        </a-descriptions>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import { oweDetail, updateFeePayOrder } from "@/api/info/payRecordApi";
import useOweRecord from "../../../../hooks/Info/useOweRecord";

export default {
  name: "Edit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { freeRecordVo, money } = useOweRecord();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = async () => {
      if (!freeRecordVo.reason) {
        showTipMessage("请输入免单原因", "error");
        return;
      }
      let action = await showConfirm("是否免单?");
      if (action) {
        disabled.value = true;
        await showLoading();
        try {
          await updateFeePayOrder(freeRecordVo.parkingRecordId, {
            remark: freeRecordVo.remark,
            reason: freeRecordVo.reason,
          });
          await closeLoading();
          await showTipMessage("修改成功");
          refresh = true;
          visible.value = false;
        } catch (e) {
          await closeLoading();
          await showErr(e);
          disabled.value = false;
        }
      }
    };
    const getOweDetail = async () => {
      try {
        let { data } = await oweDetail(props.id);
        freeRecordVo.parkingLotName = data.parkingLotName;
        freeRecordVo.slotNum = data.slotNum;
        freeRecordVo.plateNumber = data.plateNumber;
        freeRecordVo.inTime = data.inTime;
        freeRecordVo.outTime = data.outTime;
        freeRecordVo.parkingRecordId = data.id;
        freeRecordVo.shouldPayAmount = data.shouldPayAmount ? money(data.shouldPayAmount) : 0;
        freeRecordVo.paidAmount = data.paidAmount ? money(data.paidAmount) : 0;
        freeRecordVo.oweAmount = data.oweAmount ? money(data.oweAmount) : 0;
      } catch (e) {
        await showErr(e);
      }
    };
    onMounted(async () => {
      getOweDetail();
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      freeRecordVo,
      doSubmit,
      getOweDetail,
      money
    };
  },
};
</script>

<style scoped></style>
