<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item style="padding-bottom: 10px" label="车&nbsp;&nbsp;场">
          <a-select
            v-model:value="params.parkingLotId"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="请选择"
            allow-clear
          >
            <a-select-option
              v-for="item in parkingLotList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="泊位号">
          <a-input
            v-model:value="params.slotNum"
            placeholder="输入泊位号"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="车牌号">
          <a-input
            v-model:value="params.plateNumber"
            placeholder="输入车牌号"
            allow-clear
          ></a-input>
        </a-form-item>
      </a-form>
      <a-form layout="inline">
        <a-form-item style="padding-bottom: 10px" label="进场时间">
          <a-range-picker
            style="width: 400px"
            v-model:value="params.carInArr"
            :show-time="{ format: 'HH:mm:ss' }"
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始时间', '结束时间']"
          />
        </a-form-item>

        <a-form-item style="padding-bottom: 10px" label="出场时间">
          <a-range-picker
            style="width: 400px"
            v-model:value="params.carOutArr"
            :show-time="{ format: 'HH:mm:ss' }"
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始时间', '结束时间']"
          />
        </a-form-item>
        <a-form-item style="padding-bottom: 10px" label="审核状态">
          <a-select
            v-model:value="params.auditState"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="请选择"
            allow-clear
            :options="auditStates"
          ></a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="download">
            <template #icon>
              <ExportOutlined />
            </template>
            导出
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="id"
          table-layout="fixed"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          :scroll="{ y: 500 }"
          bordered
        >
          <template #parkingTime="{ text }">
            {{ getParkingTime(text) }}
          </template>
          <template #shouldPayAmount="{ text }">
            {{ money(text) }}
          </template>
          <template #paidAmount="{ text }">
            {{ money(text) }}
          </template>
          <template #oweAmount="{ text }">
            {{ money(text) }}
          </template>
          <template #plateNumber="{ record }">
            <a-tag :color="plateColorStyle(record.plateColor)">
              <span
                :style="{
                  color: record.plateColor == 4 ? '#001510' : '#FFFFFF',
                }"
                >{{ record.plateNumber }}</span
              ></a-tag
            >
          </template>
          <template #operation="{ record }">
            <div>
              <TipButton
                perm="info:payRecord:edit"
                title="停车记录"
                @btnClick="showEditModal(record.id)"
              >
                <template #default>
                  <ContainerOutlined />
                </template>
              </TipButton>
              <TipButton
                perm="info:oweRecord:edit"
                btn-class="btn-warning"
                title="错误免单"
                @btnClick="showFreeModal(record.id)"
              >
                <template #default>
                  <ExceptionOutlined />
                </template>
              </TipButton>
            </div>
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
  <detail
    v-if="editShow"
    :id="currentId"
    :modal-ref="$refs.modalContainer"
    @close="hideEditModal"
  ></detail>
  <free
    v-if="freeShow"
    :id="currentId"
    :modal-ref="$refs.modalContainer"
    @close="hideFreeModal"
  ></free>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import useOweRecord from "@/hooks/Info/useOweRecord";
import usePagination from "@/hooks/usePagination";
import { onMounted, reactive, ref } from "vue";
import useMessage from "@/hooks/useMessage";
import useAddExit from "@/hooks/useAddExit";
import Detail from "@/views/Info/OweRecord/components/Detail";
import Free from "@/views/Info/OweRecord/components/Free";
import { listAll } from "../../../api/parking/parkingLotApi";
import TipButton from "@/components/TipButton";
import { saveAs } from 'file-saver'
import axios from "axios";
export default {
  name: "index",
  components: {
    Detail,
    Free,
    CardHeader,
    TipButton,
  },
  setup() {
    const parkingLotList = reactive([]);
    const {
      params,
      dataList,
      total,
      loading,
      columns,
      loadData,
      money,
      getParkingTime,
      auditStates,
    } = useOweRecord();

    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);
    const { currentId, editShow, showEditModal, hideEditModal } =
      useAddExit(doQuery);

    const freeShow = ref(false);
    const showFreeModal = id => {
      currentId.value = id;
      freeShow.value = true;
    };

    const hideFreeModal = refresh => {
      freeShow.value = false;
      if (refresh) {
        doQuery();
      }
    };

    const { showErr } = useMessage();

    const download = () => {
      axios.get("/payOrder/oweListExport",{
        params: {
          payOrderId: params.payOrderId,
          parkingLotId: params.parkingLotId,
          plateNumber: params.plateNumber,
          slotNum: params.slotNum,
          auditState: params.auditState, //审核状态  0-待审核 1-已审核
          carInArr: params.carInArr ? params.carInArr.toString() : [],
          carOutArr: params.carOutArr ? params.carOutArr.toString() : [],
        },
        responseType: 'blob'
      }).then(res => {
        if(!res) return
        const blob = new Blob([res.data], { type: 'application/octet-stream' })
        saveAs(blob, decodeURIComponent(res.headers['download-filename']))
      }).catch((error) => {
        console.error(error)
      });
    };
    const plateColorStyle = val => {
      switch (val) {
        case 0:
          return "#888888";
        case 1:
          return "#0000FF";
        case 2:
          return "#FFA500";
        case 3:
          return "#000000";
        case 4:
          return "#FFFFFF";
        case 5:
          return "#008000";
        default:
          return "#FF0000";
      }
    };

    onMounted(async () => {
      try {
        let { data } = await listAll();
        parkingLotList.push(...data);
      } catch (e) {
        showErr(e);
      }
      loadData(page.value, pageSize.value);
    });

    return {
      parkingLotList,
      params,
      page,
      pageSize,
      total,
      loading,
      currentChange,
      dataList,
      doQuery,
      doRefresh,
      columns,
      money,
      getParkingTime,
      plateColorStyle,

      currentId,
      editShow,
      showEditModal,
      hideEditModal,
      freeShow,
      showFreeModal,
      hideFreeModal,
      auditStates,
      download
    };
  },
};
</script>

<style scoped></style>
